import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import locale_es from 'dayjs/locale/es'
dayjs.locale(locale_es)
dayjs.extend(localizedFormat)

Vue.config.productionTip = false
Vue.use(VueTheMask);

Vue.filter('fecha', value => {
  return  dayjs(value).format('LL')
})

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
